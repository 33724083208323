import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';

const UomValue = ({ id, conversionToBaseUom, children: renderTitle, placeholder = null }) => {
  const options = [createUomValueTextKey(id)];
  const { texts, loaded } = useSanaTexts(options);

  if (!loaded)
    return placeholder;
  const result = (conversionToBaseUom);
  return renderTitle
    ? renderTitle(result)
    : result;
}
UomValue.propTypes = {
  id: PropTypes.string,
  conversionToBaseUom: PropTypes.number,
  allowHtmlTags: PropTypes.bool,
  children: PropTypes.func,
  placeholder: PropTypes.node,
};
export const createUomValueTextKey = id => `UnitOfMeasure_${id || 'Empty'}`;

export default memo(UomValue);